<!--
 * @Description: 开票详情 invoiceDetails
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-06-07 11:33:32
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="发票详情"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="main">
      <van-row>
        <van-cell is-link
                  @click="handleClickInvoice">
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <span style="margin-right:3.07%;vertical-align: middle;font-size: 17px;font-weight: bold;color: #333333;">
              电子发票
            </span>
            <van-tag color="#19A9FC"
                     v-if="messsage===1"
                     plain>
              已开票
            </van-tag>
          </template>
        </van-cell>
        <van-cell class="up">
          <van-row style="font-size:15px;font-weight: bold;color: #333333;">
            发票信息
          </van-row>
          <van-row>
            <van-col class="textOne">发票抬头</van-col>
            <van-col class="textTwo">{{invoiceDetailsList.buyerName}}</van-col>
          </van-row>
          <van-row>
            <van-col class="textOne">税号</van-col>
            <van-col class="textTwo">{{invoiceDetailsList.buyerTaxNum}}</van-col>
          </van-row>
          <van-row>
            <van-col class="textOne">注册地址</van-col>
            <van-col class="textTwo"></van-col>
          </van-row>
          <van-row>
            <van-col class="textOne">注册电话</van-col>
            <van-col class="textTwo"></van-col>
          </van-row>
          <van-row>
            <van-col class="textOne">开户银行</van-col>
            <van-col class="textTwo"></van-col>
          </van-row>
          <van-row>
            <van-col class="textOne">开户账号</van-col>
            <van-col class="textTwo"></van-col>
          </van-row>
          <van-row>
            <van-col class="textOne">发票内容</van-col>
            <van-col class="textTwo">{{invoiceDetailsList.type}}</van-col>
          </van-row>
          <van-row>
            <van-col class="textOne">发票金额</van-col>
            <van-col class="textTwo">{{invoiceDetailsList.money}}</van-col>
          </van-row>
          <van-row>
            <van-col class="textOne">开票时间</van-col>
            <van-col class="textTwo">{{invoiceDetailsList.invoiceDate}}</van-col>
          </van-row>
          <van-row>
            <van-col class="textOne">备注</van-col>
            <van-col class="textTwo">{{invoiceDetailsList.invoiceStatusDesc}}</van-col>
          </van-row>
        </van-cell>
        <van-cell class="up">
          <van-row style="font-size:15px;font-weight: bold;color: #333333;">
            接收信息
          </van-row>
          <van-row>
            <van-col class="textOne">电子邮箱</van-col>
            <van-col class="textTwo">{{invoiceDetailsList.email}}</van-col>
          </van-row>
        </van-cell>
        <van-cell class="up">
          <van-row style="font-size:15px;font-weight: bold;color: #333333;">
            支付记录
          </van-row>
          <van-row @click="handleClickPayRecord">
            <van-col style="font-size:15px;font-weight: 400;color: #333333;"
                     :span=23>{{totalNumber}}条支付记录</van-col>
            <van-col :span=1>
              <van-image width="8"
                         height="14"
                         :src="require('@/assets/parkingPayment/arrow.png')" />
            </van-col>
          </van-row>
        </van-cell>
      </van-row>
      <van-row class="down">
        <van-button color="#666666"
                    plain
                    class="button"
                    @click="handleClickFlash">刷 新 开 票 状 态</van-button>
        <van-button color="#19A9FC"
                    class="button">申 请 重 新 开 票</van-button>
      </van-row>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      invoiceDetailsList: {}, // 企业支付集合
      totalNumber: 0, // 订单总条数
      messsage: 0, // 控制显示 已开票
      payRecordList: []// 支付订单
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    // 开票详情
    this.invoiceDetailsList = JSON.parse(this.$route.query.invoiceList)
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getPaymentRecordByInvoice()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 点击电子发票
    handleClickInvoice () {
      // 如果开票完成不可进入
      if (this.invoiceDetailsList.invoiceStatus !== 2) {
        this.$toast('开票中，请等待！')
      } else {
        const pdfWord = this.$pdfBaseUrl + this.invoiceDetailsList.pdfUrl
        window.location.href = pdfWord
        this.messsage = 1
        // this.$router.push({ name: 'invoiceFile', query: { pdfWord: pdfWord } })
      }
    },
    // 刷新开票详情
    handleClickFlash () {
      const info = {
        fpqqlsh: this.$route.query.invoiceSequence
      }
      this.$parkInvoice.getInvoiceAgain(info).then(() => {
      })
      // const info = {
      //   fpqqlsh: this.$route.query.invoiceSequence
      // }
      // this.$parkInvoice.getInvoiceAgain(info).then(res => {
      //   const list = JSON.parse(res.resultEntity)
      //   this.invoiceDetailsList = list.list[0]
      //   this.invoiceDetailsList.c_kprq = new Date(this.invoiceDetailsList.c_kprq)
      //   this.invoiceDetailsList.c_kprq = this.invoiceDetailsList.c_kprq.toLocaleDateString().replace(/\//g, '-') + ' ' + this.invoiceDetailsList.c_kprq.toTimeString().substr(0, 8)
      //   // 如果开票完成不可进入
      //   if (this.invoiceDetailsList.c_msg === '开票完成（最终状态）') {
      //     this.messsage = 1
      //   }
      //   // 注册地址过长显示
      //   if (this.invoiceDetailsList.c_address.length > 16) {
      //     this.invoiceDetailsList.c_address = this.invoiceDetailsList.c_address.substring(0, 16) + '...'
      //   }
      // })
    },
    // 获取支付记录
    getPaymentRecordByInvoice () {
      const info = {
        invoiceSequence: JSON.parse(this.$route.query.invoiceList).invoiceSequence,
        pageNum: 1,
        pageSize: 20
      }
      this.$invoiceHistory.getPaymentRecordByInvoice(info).then(res => {
        this.totalNumber = res.resultEntity.total
        res.resultEntity.list.forEach(item => {
          item.paymentMoneyAmount = Number(Number(item.paymentMoneyAmount) / 100).toFixed(2)
        })
        this.payRecordList = res.resultEntity.list
      })
    },
    // 点击支付记录
    handleClickPayRecord () {
      this.$router.push({ name: 'invoicePayList', query: { payRecordList: this.payRecordList } })
    },
    // 顶部返回按钮
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类

.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .main {
    width: 100%;
    background-color: #ffffff;
    margin-top: 12px;
  }
  .down {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0;
    background-color: #ffffff;
    .button {
      width: 45.26%;
      height: 40px;
      margin: 4px 0 0 3.07%;
      border-radius: 5px;
    }
  }
  .up .van-row {
    padding-top: 5px;
  }
  .textOne {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  .textTwo {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    float: right;
  }
}
</style>
